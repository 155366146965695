import { SignalableFactory } from '@locumsnest/core/src/lib/ngrx';

const actionableFactory = SignalableFactory.forFeature<'PreferredProfessionSpecialtyEntity'>(
  'PreferredProfessionSpecialtyEntity'
);

export class InitializeCollectionSignal extends actionableFactory.create<
  'InitializeCollection',
  Record<string, never>
>('InitializeCollection') {}

export class AddSelectedPreferredProfessionSpecialtySignal extends actionableFactory.create<
  'Add Selected Preferred Profession Specialty Signal',
  { id: number }
>('Add Selected Preferred Profession Specialty Signal') {}

export class RemoveSelectedPreferredProfessionSpecialtySignal extends actionableFactory.create<
  'Remove Selected Preferred Profession Specialty Signal',
  { id: number }
>('Remove Selected Preferred Profession Specialty Signal') {}

export class UpdateEntityStatePreferredProfessionSpecialtySignal extends actionableFactory.create<
  'Update Entity State Preferred Profession Specialty Signal',
  Record<string, never>
>('Update Entity State Preferred Profession Specialty Signal') {}

export type PreferredProfessionSpecialtySignals = InitializeCollectionSignal;
