import { SignalableFactory } from '@locumsnest/core/src/lib/ngrx';

const actionableFactory =
  SignalableFactory.forFeature<'PreferredSiteEntity'>('PreferredSiteEntity');

export class InitializeCollectionSignal extends actionableFactory.create<
  'InitializeCollection',
  Record<string, never>
>('InitializeCollection') {}

export class AddSelectedPreferredSiteSignal extends actionableFactory.create<
  'Add Selected Preferred Site Signal',
  { id: number }
>('Add Selected Preferred Site Signal') {}

export class RemoveSelectedPreferredSiteSignal extends actionableFactory.create<
  'Remove Selected Preferred Site Signal',
  { id: number }
>('Remove Selected Preferred Site Signal') {}

export class UpdateEntityStatePreferredSiteSignal extends actionableFactory.create<
  'Update Entity State Preferred Site Signal',
  Record<string, never>
>('Update Entity State Preferred Site Signal') {}

export type PreferredSiteSignals = InitializeCollectionSignal;
