import { SignalableFactory } from '@locumsnest/core/src/lib/ngrx';

const actionableFactory = SignalableFactory.forFeature<'PreferredPassportProfessionEntity'>(
  'PreferredPassportProfessionEntity'
);

export class InitializeCollectionSignal extends actionableFactory.create<
  'InitializeCollection',
  {}
>('InitializeCollection') {}

export class AddSelectedPreferredPassportProfessionTempSignal extends actionableFactory.create<
  'Add Selected Preferred Passport Profession Temp Signal',
  { id: number }
>('Add Selected Preferred Passport Profession Temp Signal') {}

export class RemoveSelectedPreferredPassportProfessionTempSignal extends actionableFactory.create<
  'Remove Selected Preferred Passport Profession Temp Signal',
  { id: number }
>('Remove Selected Preferred Passport Profession Temp Signal') {}

export class SelectAllPreferredPassportProfessionsTempSignal extends actionableFactory.create<
  'Select All Preferred Passport Professions Temp Signal',
  Record<string, never>
>('Select All Preferred Passport Professions Temp Signal') {}

export class ClearAllPreferredPassportProfessionsTempSignal extends actionableFactory.create<
  'Clear All Preferred Passport Professions Temp Signal',
  Record<string, never>
>('Clear All Preferred Passport Professions Temp Signal') {}
export type PreferredPassportProfessionSignals = InitializeCollectionSignal;
