import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { isNil } from 'lodash-es';
import { merge, of } from 'rxjs';
import { filter, first, map, mergeMap, switchMap } from 'rxjs/operators';

import {
  IFilterCategoryPayload,
  IFilterSelectAllPayload,
} from '@locumsnest/components/src/lib/interfaces/filter';
import { FilterStateService } from '@locumsnest/core';
import { MicroAppService } from '@locumsnest/core/src/lib/micro-app/micro-app.service';

import { PRODUCT_CODES } from '../../core/constants';
import {
  IPreferredProfessionEntity,
  IPreferredProfessionPostEntity,
} from '../../interfaces/api/preferred-profession-entity';
import { selectProfessionNamesByIds } from '../../profession/+state/profession.selectors';
import { loadingAdapter } from './preferred-passport-profession.adapter';
import {
  PreferredPassportProfessionPaginationMessages,
  ResetPreferredPassportProfessionPaginationMessage,
  UpsertMultipleMessage,
  UpsertPreferredPassportProfessionPageMessage,
} from './preferred-passport-profession.messages';
import { PreferredPassportProfessionPersistenceService } from './preferred-passport-profession.persistence.service';
import {
  loadingStateSelectors,
  preferredPassportProfessionPaginationSelectors,
  selectAllPreferredPassportProfessionsIds,
  selectAllPreferredPassportProfessionsTemp,
  selectPreferredPassportProfessionEntityState,
} from './preferred-passport-profession.selectors';
import * as tempMessages from './temp/preferred-passport-profession.messages';
import {
  AddSelectedPreferredPassportProfessionTempSignal,
  ClearAllPreferredPassportProfessionsTempSignal,
  RemoveSelectedPreferredPassportProfessionTempSignal,
  SelectAllPreferredPassportProfessionsTempSignal,
} from './temp/preferred-passport-profession.signals';

@Injectable({
  providedIn: 'root',
})
export class PreferredPassportProfessionService extends FilterStateService<
  IPreferredProfessionEntity,
  UpsertPreferredPassportProfessionPageMessage,
  ResetPreferredPassportProfessionPaginationMessage,
  UpsertMultipleMessage
> {
  protected readonly scope = [PRODUCT_CODES.PASSPORT_PLUS];

  constructor(
    protected store: Store,
    protected persistenceService: PreferredPassportProfessionPersistenceService,
    protected microAppService: MicroAppService
  ) {
    super();
  }
  get paginationMessages() {
    return PreferredPassportProfessionPaginationMessages;
  }

  get paginationSelectors() {
    return preferredPassportProfessionPaginationSelectors;
  }

  get entityStateSelector() {
    return selectPreferredPassportProfessionEntityState;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get loadingMessages() {
    return loadingAdapter.getMessages();
  }

  getPreferredPassportProfessionByProfessionId(professionId) {
    return this.getAllTemp().pipe(
      map((preferredPassportProfessions) =>
        preferredPassportProfessions.find((x) => x.profession === professionId)
      )
    );
  }

  getAllTempProfessionIds() {
    return this.getAllTemp().pipe(
      map((professions) => professions.map(({ profession }) => profession))
    );
  }

  getAllTemp(namespace: string = null) {
    const allEntities = isNil(namespace);

    namespace = allEntities ? 'default' : namespace;

    return this.isFilterEnabled$.pipe(
      switchMap((isFilterEnabled) => {
        if (isFilterEnabled) {
          return this.store.pipe(select(selectAllPreferredPassportProfessionsTemp));
        }

        return of<IPreferredProfessionEntity[]>([]);
      })
    );
  }

  getAllIds() {
    return this.store.pipe(select(selectAllPreferredPassportProfessionsIds));
  }

  getAllIdsAfterLoading() {
    return this.store.pipe(select(loadingStateSelectors.selectLoadingState)).pipe(
      filter((loadingState) => loadingState.isLoaded === true),
      mergeMap(() => this.getAllIds())
    );
  }

  getPreferredProfessionNames() {
    return this.getAllIdsAfterLoading().pipe(
      mergeMap((professionIds) =>
        this.store.pipe(select(selectProfessionNamesByIds(professionIds)))
      )
    );
  }

  fetch() {
    return merge(
      this.loadAllPages('default', null),
      this.isLoaded('default').pipe(
        filter((isLoaded) => isLoaded === true),
        first(),
        mergeMap((isLoaded) =>
          this.getAll().pipe(map((entities) => new tempMessages.SetCollectionMessage({ entities })))
        )
      )
    );
  }

  dispatchToggleSignal(payload: IFilterCategoryPayload) {
    if (!payload.selected) {
      return this.store.dispatch(
        new AddSelectedPreferredPassportProfessionTempSignal({ id: payload.id })
      );
    } else {
      return this.store.dispatch(
        new RemoveSelectedPreferredPassportProfessionTempSignal({ id: payload.id })
      );
    }
  }

  dispatchToggleAllSignal(payload: IFilterSelectAllPayload) {
    if (payload.selected) {
      return this.store.dispatch(new SelectAllPreferredPassportProfessionsTempSignal({}));
    } else {
      return this.store.dispatch(new ClearAllPreferredPassportProfessionsTempSignal({}));
    }
  }

  bulkCreate(professions: IPreferredProfessionPostEntity[]) {
    return this.persistenceService.bulkCreateFilter(professions).pipe(
      map(
        (response: IPreferredProfessionEntity[]) =>
          new tempMessages.UpsertMultipleMessage({
            entities: response,
          })
      )
    );
  }

  bulkDelete(professions: IPreferredProfessionPostEntity[]) {
    const professionIds = professions.map(({ profession }) => profession);
    return this.persistenceService.clear({ profession: professionIds }).pipe(
      map(
        () =>
          new tempMessages.DeleteMultipleMessage({
            ids: professionIds,
          })
      )
    );
  }

  clear() {
    return this.persistenceService
      .clear({})
      .pipe(map(() => new tempMessages.ResetCollectionMessage({})));
  }
}
