import { SignalableFactory } from '@locumsnest/core/src/lib/ngrx';

const actionableFactory = SignalableFactory.forFeature<'PreferredSpecialtyCategoryEntity'>(
  'PreferredSpecialtyCategoryEntity'
);

export class InitializeCollectionSignal extends actionableFactory.create<
  'InitializeCollection',
  Record<string, never>
>('InitializeCollection') {}

export class AddSelectedPreferredSpecialtyCategorySignal extends actionableFactory.create<
  'Add Selected Preferred Specialty Category Signal',
  { id: number }
>('Add Selected Preferred Specialty Category Signal') {}

export class RemoveSelectedPreferredSpecialtyCategorySignal extends actionableFactory.create<
  'Remove Selected Preferred Specialty Category Signal',
  { id: number }
>('Remove Selected Preferred Specialty Category Signal') {}

export class UpdateEntityStatePreferredSpecialtyCategorySignal extends actionableFactory.create<
  'Update Entity State Preferred Specialty Category Signal',
  Record<string, never>
>('Update Entity State Preferred Specialty Category Signal') {}

export type PreferredSpecialtyCategorySignals = InitializeCollectionSignal;
