import { SignalableFactory } from '@locumsnest/core/src/lib/ngrx';

const actionableFactory =
  SignalableFactory.forFeature<'PreferredRosterEntity'>('PreferredRosterEntity');

export class InitializeCollectionSignal extends actionableFactory.create<
  'InitializeCollection',
  Record<string, never>
>('InitializeCollection') {}

export class AddSelectedPreferredRosterSignal extends actionableFactory.create<
  'Add Selected Preferred Roster Signal',
  { id: number }
>('Add Selected Preferred Roster Signal') {}

export class RemoveSelectedPreferredRosterSignal extends actionableFactory.create<
  'Remove Selected Preferred Roster Signal',
  { id: number }
>('Remove Selected Preferred Roster Signal') {}

export class UpdateEntityStatePreferredRosterSignal extends actionableFactory.create<
  'Update Entity State Preferred Roster Signal',
  Record<string, never>
>('Update Entity State Preferred Roster Signal') {}

export type PreferredRosterSignals = InitializeCollectionSignal;
