import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  IPreferredPassportProfessionEntityState,
  IPreferredPassportProfessionFeatureState,
  IPreferredPassportProfessionSharedState,
} from './interfaces';
import { loadingAdapter, paginationAdapter } from './preferred-passport-profession.adapter';
import { selectAll, selectAllTemp } from './preferred-passport-profession.reducer';

export const getEntityState = (state: { entityState: IPreferredPassportProfessionEntityState }) =>
  state.entityState;

export const getTempState = (state: { tempState: IPreferredPassportProfessionEntityState }) =>
  state.tempState;

export const getPreferredPassportProfessionsIds = (
  state: IPreferredPassportProfessionEntityState
) => (state.ids as number[]).map((id: number) => state.entities[id].profession);

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectPreferredPassportProfessionState = createFeatureSelector<
  IPreferredPassportProfessionFeatureState & IPreferredPassportProfessionSharedState
>('preferredPassportProfessions');

// Entity State
export const selectPreferredPassportProfessionEntityState = createSelector(
  selectPreferredPassportProfessionState,
  getEntityState
);

export const selectAllPreferredPassportProfessions = createSelector(
  selectPreferredPassportProfessionEntityState,
  selectAll
);

export const selectAllPreferredPassportProfessionsIds = createSelector(
  selectPreferredPassportProfessionEntityState,
  getPreferredPassportProfessionsIds
);

// Temp State
export const selectPreferredPassportProfessionTempState = createSelector(
  selectPreferredPassportProfessionState,
  getTempState
);

export const selectAllPreferredPassportProfessionsTemp = createSelector(
  selectPreferredPassportProfessionTempState,
  selectAllTemp
);

export const selectAllPreferredPassportProfessionsIdsTemp = createSelector(
  selectPreferredPassportProfessionTempState,
  getPreferredPassportProfessionsIds
);

export const loadingStateSelectors = loadingAdapter.getSelectors(
  selectPreferredPassportProfessionState
);

export const preferredPassportProfessionPaginationSelectors = paginationAdapter.paginationSelectors(
  selectPreferredPassportProfessionState,
  loadingAdapter,
  'pagination',
  'entityState',
  false
);
