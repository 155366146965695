import { SignalableFactory } from '@locumsnest/core/src/lib/ngrx';

const actionableFactory = SignalableFactory.forFeature<'PreferredProfessionEntity'>(
  'PreferredProfessionEntity'
);

export class InitializeCollectionSignal extends actionableFactory.create<
  'InitializeCollection',
  Record<string, never>
>('InitializeCollection') {}

export class AddSelectedPreferredProfessionSignal extends actionableFactory.create<
  'Add Selected Preferred Profession Signal',
  { id: number }
>('Add Selected Preferred Profession Signal') {}

export class RemoveSelectedPreferredProfessionSignal extends actionableFactory.create<
  'Remove Selected Preferred Profession Signal',
  { id: number }
>('Remove Selected Preferred Profession Signal') {}

export class UpdateEntityStatePreferredProfessionSignal extends actionableFactory.create<
  'Update Entity State Preferred Profession Signal',
  Record<string, never>
>('Update Entity State Preferred Profession Signal') {}

export type PreferredProfessionSignals = InitializeCollectionSignal;
