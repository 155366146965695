import { SignalableFactory } from '@locumsnest/core/src/lib/ngrx';

const actionableFactory = SignalableFactory.forFeature<'PreferredPassportProfessionEntity'>(
  'PreferredPassportProfessionEntity'
);

export class InitializeCollectionSignal extends actionableFactory.create<
  'InitializeCollection',
  {}
>('InitializeCollection') {}

export class AddSelectedPreferredPassportProfessionSignal extends actionableFactory.create<
  'Add Selected Preferred Profession Signal',
  { id: number }
>('Add Selected Preferred Profession Signal') {}

export class RemoveSelectedPreferredPassportProfessionSignal extends actionableFactory.create<
  'Remove Selected Preferred Profession Signal',
  { id: number }
>('Remove Selected Preferred Profession Signal') {}

export class UpdateEntityStatePreferredPassportProfessionSignal extends actionableFactory.create<
  'Update Entity State Preferred Profession Signal',
  {}
>('Update Entity State Preferred Profession Signal') {}

export type PreferredPassportProfessionSignals = InitializeCollectionSignal;
