import { SignalableFactory } from '@locumsnest/core/src/lib/ngrx';

const actionableFactory =
  SignalableFactory.forFeature<'PreferredWardEntity'>('PreferredWardEntity');

export class InitializeCollectionSignal extends actionableFactory.create<
  'InitializeCollection',
  Record<string, never>
>('InitializeCollection') {}

export class AddSelectedPreferredWardSignal extends actionableFactory.create<
  'Add Selected Preferred Ward Signal',
  { id: number }
>('Add Selected Preferred Ward Signal') {}

export class RemoveSelectedPreferredWardSignal extends actionableFactory.create<
  'Remove Selected Preferred Ward Signal',
  { id: number }
>('Remove Selected Preferred Ward Signal') {}

export class UpdateEntityStatePreferredWardSignal extends actionableFactory.create<
  'Update Entity State Preferred Ward Signal',
  Record<string, never>
>('Update Entity State Preferred Ward Signal') {}

export type PreferredWardSignals = InitializeCollectionSignal;
