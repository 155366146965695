import { SignalableFactory } from '@locumsnest/core/src/lib/ngrx';

const actionableFactory = SignalableFactory.forFeature<'PreferredProfessionEntity'>(
  'PreferredProfessionEntity'
);

export class InitializeCollectionSignal extends actionableFactory.create<
  'InitializeCollection',
  Record<string, never>
>('InitializeCollection') {}

export class AddSelectedPreferredProfessionTempSignal extends actionableFactory.create<
  'Add Selected Preferred Profession Temp Signal',
  { id: number }
>('Add Selected Preferred Profession Temp Signal') {}

export class RemoveSelectedPreferredProfessionTempSignal extends actionableFactory.create<
  'Remove Selected Preferred Profession Temp Signal',
  { id: number }
>('Remove Selected Preferred Profession Temp Signal') {}

export class SelectAllPreferredProfessionsTempSignal extends actionableFactory.create<
  'Select All Preferred Professions Temp Signal',
  Record<string, never>
>('Select All Preferred Professions Temp Signal') {}

export class ClearAllPreferredProfessionsTempSignal extends actionableFactory.create<
  'Clear All Preferred Professions Temp Signal',
  Record<string, never>
>('Clear All Preferred Professions Temp Signal') {}

export type PreferredProfessionSignals = InitializeCollectionSignal;
