import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IPreferredProfessionEntity } from '../../interfaces/api/preferred-profession-entity';
import {
  IPreferredProfessionEntityState,
  IPreferredProfessionFeatureState,
  IPreferredProfessionSharedState,
} from './interfaces';
import { loadingAdapter, paginationAdapter } from './preferred-profession.adapter';
import { selectAll, selectAllTemp, selectEntities } from './preferred-profession.reducer';

export const getEntityState = (state: { entityState: IPreferredProfessionEntityState }) =>
  state.entityState;

export const getTempState = (state: { tempState: IPreferredProfessionEntityState }) =>
  state.tempState;

export const getPreferredProfessionsIds = (state: IPreferredProfessionEntity[]) =>
  state.map((e: IPreferredProfessionEntity) => e.profession);

/**
 * Create Feature Selectors based on the above selectors
 */
export const selectPreferredProfessionState = createFeatureSelector<
  IPreferredProfessionFeatureState & IPreferredProfessionSharedState
>('preferredProfessions');

// Entity State
export const selectPreferredProfessionEntityState = createSelector(
  selectPreferredProfessionState,
  getEntityState
);

export const selectAllPreferredProfessions = createSelector(
  selectPreferredProfessionEntityState,
  selectAll
);
export const selectPreferredProfessionsDict = createSelector(
  selectPreferredProfessionEntityState,
  selectEntities
);
export const selectAllPreferredProfessionsIds = createSelector(
  selectAllPreferredProfessions,
  getPreferredProfessionsIds
);

// Temp State
export const selectPreferredProfessionTempState = createSelector(
  selectPreferredProfessionState,
  getTempState
);

export const selectAllPreferredProfessionsTemp = createSelector(
  selectPreferredProfessionTempState,
  selectAllTemp
);

export const selectAllPreferredProfessionsIdsTemp = createSelector(
  selectAllPreferredProfessionsTemp,
  getPreferredProfessionsIds
);

export const loadingStateSelectors = loadingAdapter.getSelectors(selectPreferredProfessionState);

export const preferredProfessionPaginationSelectors = paginationAdapter.paginationSelectors(
  selectPreferredProfessionState,
  loadingAdapter,
  'pagination',
  'entityState',
  false
);
