import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { merge, of } from 'rxjs';
import { filter, first, map, mergeMap, switchMap } from 'rxjs/operators';

import { IFilterCategoryPayload } from '@locumsnest/components/src/lib/interfaces/filter';
import { FilterStateService } from '@locumsnest/core';
import { MicroAppService } from '@locumsnest/core/src/lib/micro-app/micro-app.service';

import { PRODUCT_CODES } from '../../core/constants';
import {
  IPreferredProfessionEntity,
  IPreferredProfessionPostEntity,
} from '../../interfaces/api/preferred-profession-entity';
import { PreferredPassportProfessionService } from '../../preferred-passport-profession/+state/preferred-passport-profession.service';
import { UpdateEntityStatePreferredPassportProfessionSignal } from '../../preferred-passport-profession/+state/preferred-passport-profession.signals';
import { UpdateEntityStatePreferredProfessionSpecialtySignal } from '../../preferred-profession-specialty/+state/preferred-profession-specialty.signals';
import { UpdateEntityStatePreferredRosterSignal } from '../../preferred-roster/+state/preferred-roster.signals';
import { UpdateEntityStatePreferredSiteSignal } from '../../preferred-site/+state/preferred-site.signals';
import { UpdateEntityStatePreferredSpecialtyCategorySignal } from '../../preferred-specialty-category/+state/preferred-specialty-category.signals';
import { UpdateEntityStatePreferredWardSignal } from '../../preferred-ward/+state/preferred-ward.signals';
import { ProfessionSpecialtyService } from '../../profession-specialty/+state/profession-specialty.service';
import { selectProfessionNamesByIds } from '../../profession/+state/profession.selectors';
import { IFilterSelectAllPayload } from './../../../../../../libs/components/src/lib/interfaces/filter';
import { loadingAdapter } from './preferred-profession.adapter';
import {
  PreferredProfessionPaginationMessages,
  ResetPreferredProfessionPaginationMessage,
  UpsertMultipleMessage,
  UpsertPreferredProfessionPageMessage,
} from './preferred-profession.messages';
import { PreferredProfessionPersistenceService } from './preferred-profession.persistence.service';
import {
  loadingStateSelectors,
  preferredProfessionPaginationSelectors,
  selectAllPreferredProfessionsIds,
  selectAllPreferredProfessionsTemp,
  selectPreferredProfessionEntityState,
} from './preferred-profession.selectors';
import { UpdateEntityStatePreferredProfessionSignal } from './preferred-profession.signals';
import * as tempMessages from './temp/preferred-profession.messages';
import {
  ClearAllPreferredProfessionsTempSignal,
  SelectAllPreferredProfessionsTempSignal,
} from './temp/preferred-profession.signals';
import {
  AddSelectedPreferredProfessionTempSignal,
  RemoveSelectedPreferredProfessionTempSignal,
} from './temp/preferred-profession.signals';

@Injectable({
  providedIn: 'root',
})
export class PreferredProfessionService extends FilterStateService<
  IPreferredProfessionEntity,
  UpsertPreferredProfessionPageMessage,
  ResetPreferredProfessionPaginationMessage,
  UpsertMultipleMessage
> {
  protected readonly scope = [
    PRODUCT_CODES.MATCH,
    PRODUCT_CODES.LINK,
    PRODUCT_CODES.INTELLIGENCE,
    PRODUCT_CODES.COMMUNITY,
  ];

  constructor(
    protected store: Store,
    private professionSpecialtyService: ProfessionSpecialtyService,
    protected persistenceService: PreferredProfessionPersistenceService,
    protected preferredPassportProfessionService: PreferredPassportProfessionService,
    protected microAppService: MicroAppService
  ) {
    super();
  }
  get paginationMessages() {
    return PreferredProfessionPaginationMessages;
  }

  get paginationSelectors() {
    return preferredProfessionPaginationSelectors;
  }

  get entityStateSelector() {
    return selectPreferredProfessionEntityState;
  }

  get upsertMultipleMessage() {
    return UpsertMultipleMessage;
  }

  get loadingMessages() {
    return loadingAdapter.getMessages();
  }

  getOneByProfessionId(professionId) {
    return this.getAll().pipe(
      map((preferredProfessions) => preferredProfessions.find((x) => x.profession === professionId))
    );
  }

  getPreferredProfessionByProfessionId(professionId) {
    return this.getAllTemp().pipe(
      map((preferredProfessions) => preferredProfessions.find((x) => x.profession === professionId))
    );
  }

  getPreferredProfessionByProfessionIdList(professionIdList) {
    return this.getAllTemp().pipe(
      map((preferredProfessions) =>
        preferredProfessions.filter((x) => professionIdList.includes(x.profession))
      )
    );
  }

  getPreferredProfessionCategoriesByProfessionId(professionId) {
    return this.getPreferredProfessionByProfessionId(professionId).pipe(
      mergeMap((preferredProfession) =>
        preferredProfession
          ? this.professionSpecialtyService.getActiveCategoriesByProfessionId(
              preferredProfession.profession
            )
          : of([])
      )
    );
  }

  getPreferredProfessionCategoriesByProfessionIdList(professionIdList) {
    return this.getPreferredProfessionByProfessionIdList(professionIdList).pipe(
      mergeMap((preferredProfessions) =>
        preferredProfessions.length
          ? this.professionSpecialtyService.getActiveCategoriesByProfessionIdList(
              preferredProfessions.map(({ profession }) => profession)
            )
          : of([])
      )
    );
  }
  getPreferredProfessionNames() {
    return this.getAllIdsAfterLoading().pipe(
      mergeMap((professionIds) =>
        this.store.pipe(select(selectProfessionNamesByIds(professionIds)))
      )
    );
  }

  getAllTemp() {
    return this.isFilterEnabled$.pipe(
      switchMap((isFilterEnabled) => {
        if (isFilterEnabled) {
          return this.store.pipe(select(selectAllPreferredProfessionsTemp));
        }

        return of<IPreferredProfessionEntity[]>([]);
      })
    );
  }

  getAllTempProfessionIds() {
    return this.getAllTemp().pipe(
      map((professions) => professions.map(({ profession }) => profession))
    );
  }

  getAllAfterLoading() {
    return this.store.pipe(select(loadingStateSelectors.selectLoadingState)).pipe(
      filter((loadingState) => loadingState.isLoaded === true),
      mergeMap(() => this.getAll())
    );
  }

  getAllIds() {
    return this.store.pipe(select(selectAllPreferredProfessionsIds));
  }

  getAllIdsAfterLoading() {
    return this.store.pipe(select(loadingStateSelectors.selectLoadingState)).pipe(
      filter((loadingState) => loadingState.isLoaded === true),
      mergeMap(() => this.getAllIds())
    );
  }

  fetch() {
    return merge(
      this.loadAllPages('default', null),
      this.isLoaded('default').pipe(
        filter((isLoaded) => isLoaded === true),
        first(),
        mergeMap(() =>
          this.getAll().pipe(map((entities) => new tempMessages.SetCollectionMessage({ entities })))
        )
      )
    );
  }

  dispatchToggleSignals(payload: IFilterCategoryPayload) {
    if (!payload.selected) {
      return this.store.dispatch(new AddSelectedPreferredProfessionTempSignal({ id: payload.id }));
    } else {
      return this.store.dispatch(
        new RemoveSelectedPreferredProfessionTempSignal({ id: payload.id })
      );
    }
  }

  bulkCreate(professions: IPreferredProfessionPostEntity[]) {
    return this.persistenceService.bulkCreateFilter(professions).pipe(
      map(
        (response: IPreferredProfessionEntity[]) =>
          new tempMessages.UpsertMultipleMessage({
            entities: response,
          })
      )
    );
  }

  bulkDelete(professions: IPreferredProfessionPostEntity[]) {
    const professionIds = professions.map(({ profession }) => profession);
    return this.persistenceService.clear({ profession: professionIds }).pipe(
      map(
        () =>
          new tempMessages.DeleteMultipleMessage({
            ids: professionIds,
          })
      )
    );
  }

  clear() {
    return this.persistenceService
      .clear({})
      .pipe(map(() => new tempMessages.ResetCollectionMessage({})));
  }

  dispatchUpdateEntitySignals() {
    return merge(
      this.isFilterEnabled$.pipe(
        first(),
        filter((isEnabled) => isEnabled === true),
        mergeMap(() => {
          this.store.dispatch(new UpdateEntityStatePreferredProfessionSpecialtySignal({}));
          this.store.dispatch(new UpdateEntityStatePreferredSpecialtyCategorySignal({}));
          this.store.dispatch(new UpdateEntityStatePreferredSiteSignal({}));
          this.store.dispatch(new UpdateEntityStatePreferredProfessionSignal({}));
          this.store.dispatch(new UpdateEntityStatePreferredWardSignal({}));
          this.store.dispatch(new UpdateEntityStatePreferredRosterSignal({}));
          return of<Action>();
        })
      ),
      this.preferredPassportProfessionService.isFilterEnabled$.pipe(
        first(),
        filter((isEnabled) => isEnabled === true),
        mergeMap(() => {
          this.store.dispatch(new UpdateEntityStatePreferredPassportProfessionSignal({}));
          return of<Action>();
        })
      )
    );
  }

  hasPreferredProfessions() {
    return merge(
      this.isFilterEnabled$.pipe(
        filter((isEnabled) => isEnabled === true),
        mergeMap(() => this.getAllTempProfessionIds())
      ),
      this.preferredPassportProfessionService.isFilterEnabled$.pipe(
        filter((isEnabled) => isEnabled === true),
        mergeMap(() => this.preferredPassportProfessionService.getAllTempProfessionIds())
      )
    );
  }
  dispatchToggleAllSignal(payload: IFilterSelectAllPayload) {
    if (payload.selected) {
      return this.store.dispatch(new SelectAllPreferredProfessionsTempSignal({}));
    } else {
      return this.store.dispatch(new ClearAllPreferredProfessionsTempSignal({}));
    }
  }
}
