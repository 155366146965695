import { IPaginatedEntityState } from '@locumsnest/core/src/lib/interfaces/pagination';
import { combineReducers } from '@ngrx/store';
import { IPreferredProfessionEntity } from '../../interfaces/api/preferred-profession-entity';
import { IPreferredPassportProfessionEntityState } from './interfaces/index';
import {
  adapter,
  initialState,
  loadingAdapter,
  paginationAdapter,
} from './preferred-passport-profession.adapter';
import {
  PreferredPassportProfessionMessages,
  PreferredPassportProfessionMessageTypes as MessageTypes,
} from './preferred-passport-profession.messages';
import { tempReducer } from './temp/preferred-passport-profession.reducer';

export * from './interfaces';

const loadingStateReducer = loadingAdapter.createReducer();

export const paginationReducer = paginationAdapter.createReducer();

export type IPreferredPassportProfessionPaginatedEntityState =
  IPreferredPassportProfessionEntityState & IPaginatedEntityState<IPreferredProfessionEntity>;

export function entityReducer(
  state = initialState,
  action: PreferredPassportProfessionMessages
): IPreferredPassportProfessionEntityState {
  switch (action.type) {
    case MessageTypes.ADD_ONE: {
      return adapter.setOne(action.payload.entity, state);
    }

    case MessageTypes.UPSERT_ONE: {
      return adapter.upsertOne(action.payload.entity, state);
    }

    case MessageTypes.SET_COLLECTION: {
      return adapter.setAll(action.payload.entities, state);
    }

    case MessageTypes.ADD_MULTIPLE: {
      return adapter.addMany(action.payload.entities, state);
    }

    case MessageTypes.UPSERT_MULTIPLE: {
      return adapter.upsertMany(action.payload.entities, state);
    }

    case MessageTypes.UPDATE_ONE: {
      return adapter.updateOne(action.payload.entity, state);
    }

    case MessageTypes.UPDATE_MULTIPLE: {
      return adapter.updateMany(action.payload.entities, state);
    }

    case MessageTypes.DELETE_ONE: {
      return adapter.removeOne(action.payload.id, state);
    }

    case MessageTypes.DELETE_MULTIPLE: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case MessageTypes.UPDATE_ENTITY_STATE: {
      return action.payload.preferredPassportProfessionTempState;
    }

    default: {
      return state;
    }
  }
}

export function reducer(state, action) {
  return combineReducers({
    entityState: entityReducer,
    tempState: tempReducer,
    entityLoadingState: loadingStateReducer,
    pagination: paginationReducer,
  })(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } =
  paginationAdapter.getEntitySelectors();

export const { selectAll: selectAllTemp } = adapter.getSelectors();
