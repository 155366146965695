import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { IPreferredProfessionEntity } from '../../interfaces/api/preferred-profession-entity';
import { IPreferredPassportProfessionEntityState } from './interfaces';
import { paginationAdapter } from './preferred-passport-profession.adapter';

export const PreferredPassportProfessionPaginationMessages = paginationAdapter.getMessages();

export class UpsertPreferredPassportProfessionPageMessage extends PreferredPassportProfessionPaginationMessages.UpsertPageMessage {}
export class UpsertPreferredPassportProfessionMultiplePagesMessage extends PreferredPassportProfessionPaginationMessages.UpsertMultiplePagesMessage {}
export class ResetPreferredPassportProfessionPaginationMessage extends PreferredPassportProfessionPaginationMessages.ResetPaginationMessage {}

export enum PreferredPassportProfessionSignalTypes {
  LOAD_PREFERRED_PASSPORT_PROFESSIONS = '[PreferredPassportProfession][Signal] Load Collection',
}
export enum PreferredPassportProfessionMessageTypes {
  SET_COLLECTION = '[PreferredPassportProfession][Message] Set Collection',
  ADD_ONE = '[PreferredPassportProfession][Message] Add One',
  UPDATE_ONE = '[PreferredPassportProfession][Message] Update One',
  UPSERT_ONE = '[PreferredPassportProfession][Message] Upsert One',
  DELETE_ONE = '[PreferredPassportProfession][Message] Delete One',
  ADD_MULTIPLE = '[PreferredPassportProfession][Message] Add All',
  DELETE_MULTIPLE = '[PreferredPassportProfession][Message] Delete Many',
  UPSERT_MULTIPLE = '[PreferredPassportProfession][Message] Upsert Many',
  UPDATE_MULTIPLE = '[PreferredPassportProfession][Message] Update Many',
  UPDATE_ENTITY_STATE = '[PreferredPassportProfession][Message] Update Entity State',
}
export class SetCollectionMessage implements Action {
  readonly type = PreferredPassportProfessionMessageTypes.SET_COLLECTION;
  constructor(public payload: { entities: IPreferredProfessionEntity[] }) {}
}

export class AddOneMessage implements Action {
  readonly type = PreferredPassportProfessionMessageTypes.ADD_ONE;

  constructor(public payload: { entity: IPreferredProfessionEntity }) {}
}

export class UpsertOneMessage implements Action {
  readonly type = PreferredPassportProfessionMessageTypes.UPSERT_ONE;

  constructor(public payload: { entity: IPreferredProfessionEntity }) {}
}

export class AddMultipleMessage implements Action {
  readonly type = PreferredPassportProfessionMessageTypes.ADD_MULTIPLE;

  constructor(public payload: { entities: IPreferredProfessionEntity[] }) {}
}

export class UpsertMultipleMessage implements Action {
  readonly type = PreferredPassportProfessionMessageTypes.UPSERT_MULTIPLE;

  constructor(public payload: { entities: IPreferredProfessionEntity[] }) {}
}

export class UpdateOneMessage implements Action {
  readonly type = PreferredPassportProfessionMessageTypes.UPDATE_ONE;

  constructor(public payload: { entity: Update<IPreferredProfessionEntity> }) {}
}

export class UpdateMultipleMessage implements Action {
  readonly type = PreferredPassportProfessionMessageTypes.UPDATE_MULTIPLE;

  constructor(public payload: { entities: Update<IPreferredProfessionEntity>[] }) {}
}

export class DeleteOneMessage implements Action {
  readonly type = PreferredPassportProfessionMessageTypes.DELETE_ONE;

  constructor(public payload: { id: number }) {}
}

export class DeleteMultipleMessage implements Action {
  readonly type = PreferredPassportProfessionMessageTypes.DELETE_MULTIPLE;

  constructor(public payload: { ids: number[] }) {}
}

export class UpdateEntityStateMessage implements Action {
  readonly type = PreferredPassportProfessionMessageTypes.UPDATE_ENTITY_STATE;

  constructor(
    public payload: {
      preferredPassportProfessionTempState: IPreferredPassportProfessionEntityState;
    }
  ) {}
}

export type PreferredPassportProfessionMessages =
  | SetCollectionMessage
  | AddOneMessage
  | UpsertOneMessage
  | AddMultipleMessage
  | UpsertMultipleMessage
  | UpdateOneMessage
  | UpdateMultipleMessage
  | DeleteOneMessage
  | DeleteMultipleMessage
  | UpdateEntityStateMessage;
